var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated fadeIn"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('strong',[_vm._v("Discount")])]),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('form-group',{attrs:{"validator":_vm.$v.form.period,"label":"Period","use-horizontal":false}},[_c('date-range-picker',{staticStyle:{"width":"100%","cursor":"pointer"},attrs:{"opens":"center","locale-data":{ format: 'dd/mm/yyyy', separator: ' to ' },"control-container-class":`form-control ${
                    _vm.validatePeriod() == false
                      ? 'is-invalid'
                      : _vm.validatePeriod() == true
                      ? 'is-valid'
                      : null
                  }`},model:{value:(_vm.form.period),callback:function ($$v) {_vm.$set(_vm.form, "period", $$v)},expression:"form.period"}}),(_vm.validatePeriod() == false)?[_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"inline !important"}},[_vm._v("\n                    Amount must not be empty\n                  ")])]:_vm._e()],2)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('form-group',{attrs:{"validator":_vm.$v.form.amount,"label":"Amount (SGD)","use-horizontal":false}},[_c('vue-numeric-input',{attrs:{"step":2,"controls":false,"className":"form-control","width":"100%"},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})],1)],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","size":"sm","variant":"success"}},[_vm._v("\n              Save Changes\n            ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }