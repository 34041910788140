<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-card>
            <div slot="header">
              <strong>Discount</strong>
            </div>
            <b-row>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.period"
                  label="Period"
                  :use-horizontal="false"
                >
                  <date-range-picker
                    v-model="form.period"
                    style="width: 100%; cursor: pointer"
                    opens="center"
                    :locale-data="{ format: 'dd/mm/yyyy', separator: ' to ' }"
                    :control-container-class="`form-control ${
                      validatePeriod() == false
                        ? 'is-invalid'
                        : validatePeriod() == true
                        ? 'is-valid'
                        : null
                    }`"
                  />
                  <template v-if="validatePeriod() == false">
                    <div
                      class="invalid-feedback"
                      style="display: inline !important"
                    >
                      Amount must not be empty
                    </div>
                  </template>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.amount"
                  label="Amount (SGD)"
                  :use-horizontal="false"
                >
                  <vue-numeric-input
                    v-model="form.amount"
                    :step="2"
                    :controls="false"
                    className="form-control"
                    width="100%"
                  ></vue-numeric-input>
                </form-group>
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button
                type="submit"
                size="sm"
                variant="success"
                class="float-right"
              >
                Save Changes
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, minValue, decimal } from "vuelidate/lib/validators";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    DateRangePicker,
  },
  data: () => ({
    form: {
      period: {
        startDate: null,
        endDate: null,
      },
      amount: null,
    },
  }),
  validations: {
    form: {
      period: {
        startDate: { required },
        endDate: { required },
      },
      amount: { required, minValue: minValue(1), decimal },
    },
  },
  created() {
    const self = this;
    self.get();
  },
  methods: {
    get() {
      const self = this;

      let loader = self.$loading.show();
      self.$store
        .dispatch("apis/get", {
          url: `/transaction/discount`,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            if (typeof response.data != "undefined") self.form = response.data;
          }
          loader.hide();
        });
    },
    validatePeriod() {
      const self = this;
      if (self.$v.form.period) {
        return self.$v.form.period.startDate.$dirty
          ? !self.$v.form.period.startDate.$error
          : null;
      }

      return null;
    },
    handleSubmit() {
      const self = this;

      self.$v.form.$touch();
      if (self.$v.form.$pending || self.$v.form.$error) return;

      let _confirmText = "You are about to submit this data. Are you sure ?",
        _okText = "Yes, Submit",
        _action = "apis/post",
        _url = "/transaction/discount";

      self.$dialog
        .confirm(_confirmText, {
          okText: _okText,
          cancelText: "Cancel",
          loader: true,
        })
        .then((dialog) => {
          self.$store
            .dispatch(_action, {
              url: _url,
              params: self.form,
            })
            .then((response) => {
              dialog.close();
              if (response.error) {
                self.$message.error({
                  zIndex: 10000,
                  message: response.message,
                });
              } else {
                self.$message.success({
                  zIndex: 10000,
                  message: response.message,
                });

                self.form.amount = self.form.amount.toFixed(2);
              }
            });
        });
    },
  },
};
</script>